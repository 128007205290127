import React from 'react';
import {Container} from 'react-bootstrap';
import '../App.css';
import Header from './Header';

var Tools = () => {
    return(
        <Container>
            <Header/>
            <h3><u>Tools</u></h3>
            <p>Here are some tools that I frequent in no particular order.</p>
            <hr/>
            <h4>Enumeration & Scanning</h4>
                <ul>
                    <li>nmap</li>
                    <li>dirb</li>
                    <li>gobuster</li>
                    <li>burpsuite</li>
                </ul>
            <h4>Gaining access/Privilege escalation</h4>
                <ul>
                    <li>Linux command line tools</li>
                    <li>GTFOBins</li>
                    <li>burpsuite</li>
                    <li>netcat</li>
                    <li>John the ripper</li>
                    <li>Metasploit</li>
                </ul>
            <p>As i mostly like to partake in hack the box challenges & CTFs, I don't usually have to worry about doing any kind of anti-forensics. However as I continue to expand my knowledge of pentesting techniques, I'll probably add in sections about those in the future. </p>
        </Container>
    )
}

export default Tools;