import React from 'react';
import {Container} from 'react-bootstrap';
import '../App.css';
import Header from './Header';


var WriteUps = () => {
    return(
        <div>
        <Header/>
            <Container>     
            <u>Machines I've owned on hack the box: </u>
                <ol> 
                    <li>LaCasadePapel - 7/27/19</li>
                    <li>Writeup - 10/4/19 </li>
                    <li>Networked - 11/11/19 </li>
                    <li>Travercex - 1/30/20 </li>
                    <li>OpenAdmin - 3/15/20 </li>
                    <li>Traceback - 3/16/20 </li>
                    <li>Obscurity - 3/17/20 </li>
                </ol>

                <h5>Formalized writeups in progress</h5>

            </Container>
        </div>
    )
}

export default WriteUps;