import React from 'react';
import {Link} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import '../App.css'


var Header = () => {
    
    return(
        <div>
            <Container className="text-center">
            <Link to={"/writeups"} className='pages'> Write ups </Link> | 
            <Link to={"/tools"} className='pages'> Tools</Link> |
            <Link to={"/resources"} className='pages'> Resources </Link> 
            </Container>

            <Container className="text-center">
            
            <h1 className="text">Welcome to Gedisburg.net</h1>
            <p>my Hack the Box blog</p>
            <img id="badge" src="https://www.hackthebox.eu/badge/image/139897" alt="Hack The Box badge"></img>
            </Container>
        </div>
    )
}

export default Header;
