import React from 'react'
import {Container} from 'react-bootstrap';
import '../App.css';
import Header from './Header';

var Resources = () => {
    return(
        <Container>
            <Header/>
            <h3><u>Resources</u></h3>
            <p>Here are some learning resources I like to frequent:</p>
            <ul>
                <li><a href="https://www.youtube.com/channel/UCa6eh7gCkpPo5XXUDfygQQA">
                    IppSec</a> - an awesome YouTube channel that does very in-depth walkthroughs of hackthebox machines. 
                </li>
                <li>OWASP Juice Shop - a modern and insecure web application that functions as a great learning resource. </li>
                <li>Cryptohack - a fun platform to practice cryptography related challenges</li>
                <li>Pwnables - a great way to practice reverse engineering challenges on binaries containing flags</li>
                <li>CTFTime - a site with a list of on-going or upcoming capture the flag challenges from around the world. This is a great way to get practice. </li>
            </ul>
            <br/>
            <p>I'll add more to this list as I come across more resources that I find helpful in learning more about pen testing </p>
        </Container>
    )
}

export default Resources;