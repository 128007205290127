import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route} from 'react-router-dom';
import './App.css';

import Header from './components/Header'
import Tools from './components/Tools';
import WriteUps from './components/Writeups';
import Resources from './components/Resources';


function App() {
  return (
    <BrowserRouter>
        <div className="App">
          <Route exact path ='/' component={Header} />
          <Route path='/tools' component={Tools} />
          <Route path='/writeups' component={WriteUps} />
          <Route path='/resources' component={Resources} />
        </div>
    </BrowserRouter>
  );
}

export default App;
